import { NgIf } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AdHocEducationExam, AdHocEducationExamCreateInput, AdHocEducationExamUpdateInput } from "@ankaadia/graphql";
import { isEmpty } from "lodash";
import { PrimeTemplate } from "primeng-v17/api";
import { CardModule } from "primeng-v17/card";
import { InputTextModule } from "primeng-v17/inputtext";
import { EditToolBarComponent } from "../../../../shared/edit-tool-bar/edit-tool-bar.component";
import { FormElementMapModule } from "../../../../shared/from-element-map/form-element-map.module";
import { TestIdDirective } from "../../../../shared/test-id/test-id.directive";
import { TranslateDirective } from "../../../../shared/transloco/translate.directive";
import { EducationExamDialogAdHocFieldsComponent } from "../education-exam-dialog-ad-hoc-fields/education-exam-dialog-ad-hoc-fields.component";
import { EducationExamDialogGeneralFieldsComponent } from "../education-exam-dialog-general-fields/education-exam-dialog-general-fields.component";
import { EducationExamDialogLanguageLearningFieldsComponent } from "../education-exam-dialog-languange-learning-fields/education-exam-dialog-language-learning-fields.component";
import { AdHocEducationExamForm } from "./education-exam-ad-hoc-form.model";
import { AdHocEducationExamFormService } from "./education-exam-ad-hoc-form.service";

@Component({
  selector: "app-education-exam-ad-hoc-dialog",
  templateUrl: "./education-exam-ad-hoc-dialog.component.html",
  imports: [
    TranslateDirective,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    InputTextModule,
    FormElementMapModule,
    TestIdDirective,
    EducationExamDialogAdHocFieldsComponent,
    EducationExamDialogLanguageLearningFieldsComponent,
    EducationExamDialogGeneralFieldsComponent,
    PrimeTemplate,
    EditToolBarComponent,
  ],
})
export class EducationExamAdHocDialogComponent implements OnInit, OnChanges {
  @Input({ required: true })
  exam: Partial<AdHocEducationExam>;

  protected form: AdHocEducationExamForm;

  @Output()
  readonly saved = new EventEmitter<AdHocEducationExamCreateInput | AdHocEducationExamUpdateInput>();

  @Output()
  readonly closed = new EventEmitter<void>();

  constructor(
    private readonly formService: AdHocEducationExamFormService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form = this.formService.createForm();
    this.patchForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.exam) this.patchForm();
  }

  private patchForm(): void {
    if (!this.form) return;
    this.form.patchValue(this.exam, { emitEvent: true });
    this.form.markAsPristine();
    this.changeDetector.detectChanges();
  }

  save(): void {
    this.saved.emit(this.form.getRawValue());
  }

  cancel(): void {
    this.closed.emit();
  }

  protected readonly isEmpty = isEmpty;
}
