import { AsyncPipe, NgFor } from "@angular/common";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EducationModuleForSelectionFragment } from "@ankaadia/graphql";
import { PrimeTemplate } from "primeng-v17/api";
import { DropdownModule } from "primeng-v17/dropdown";
import { BehaviorSubject, combineLatest, map, Observable } from "rxjs";
import { FormElementMapModule } from "../../../../shared/from-element-map/form-element-map.module";
import { AppendToBodyDirective } from "../../../../shared/primeng/append-to-body/append-to-body.directive";
import { DropdownEditableColumnAutoFocusDirective } from "../../../../shared/primeng/dropdown-editable-column-auto-focus/dropdown-editable-column-auto-focus.directive";
import { DropdownHideFixDirective } from "../../../../shared/primeng/dropdown-hide-fix/dropdown-hide-fix.directive";
import { DropdownOptionsDirective } from "../../../../shared/primeng/dropdown-options/dropdown-options.directive";
import { DropdownPrePopulateSingleOptionDirective } from "../../../../shared/primeng/dropdown-pre-populate-single-option/dropdown-pre-populate-single-option.directive";
import { DropdownReadonlyFixDirective } from "../../../../shared/primeng/dropdown-readonly-fix/dropdown-readonly-fix.directive";
import { SettingsService } from "../../../../shared/services/settings.service";
import { TestIdDirective } from "../../../../shared/test-id/test-id.directive";
import { OrganizationsService } from "../../../organizations/organizations.service";

@Component({
  selector: "app-education-module-selector",
  templateUrl: "./education-module-selector.component.html",
  styleUrl: "./education-module-selector.component.scss",
  imports: [
    DropdownModule,
    AppendToBodyDirective,
    DropdownEditableColumnAutoFocusDirective,
    DropdownHideFixDirective,
    DropdownOptionsDirective,
    DropdownPrePopulateSingleOptionDirective,
    DropdownReadonlyFixDirective,
    FormsModule,
    ReactiveFormsModule,
    FormElementMapModule,
    TestIdDirective,
    NgFor,
    PrimeTemplate,
    AsyncPipe,
  ],
})
export class EducationModuleSelectorComponent implements OnChanges {
  @Input({ required: true }) educationModuleIdControl: FormControl<string>;
  @Input({ required: true }) educationModules: EducationModuleForSelectionFragment[];
  @Input({ required: true }) modulePlaceholder: string;

  modules$ = new BehaviorSubject<EducationModuleForSelectionFragment[]>([]);
  modulesForSelection$ = this.getEducationModules();

  constructor(
    private readonly organizationService: OrganizationsService,
    private readonly settings: SettingsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.educationModules) this.modules$.next(this.educationModules);
  }

  private getEducationModules(): Observable<EducationModuleForSelection[]> {
    return combineLatest([
      this.modules$,
      this.organizationService.getOrganizationsLinkedTo(this.settings.organizationId, true),
    ]).pipe(
      map(([modules, organizations]) =>
        modules.map((module) => ({
          ...module,
          organizationName: organizations.find((x) => x.id === module.organizationId)?.name ?? module.organizationId,
        }))
      )
    );
  }
}

export type EducationModuleForSelection = EducationModuleForSelectionFragment & {
  organizationName: string;
};
