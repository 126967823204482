import {
  EducationCourseAttendance,
  EducationCourseCandidateStatus,
  EducationCoursePerformanceStatus,
} from "@ankaadia/graphql";
import { PrimeIcons } from "primeng-v17/api";
import { IconColor } from "../../../../shared/services/style.helper";

export const EducationCourseCandidateStatusIcons: Record<EducationCourseCandidateStatus, string> = {
  [EducationCourseCandidateStatus.OnTrack]: IconColor.Success(PrimeIcons.CIRCLE_FILL),
  [EducationCourseCandidateStatus.Warning]: IconColor.Warning(PrimeIcons.CIRCLE_FILL),
  [EducationCourseCandidateStatus.InterventionRequired]: IconColor.Error(PrimeIcons.CIRCLE_FILL),
};

export const EducationCourseAttendanceIcons: Record<EducationCourseAttendance, string> = {
  [EducationCourseAttendance.Present]: IconColor.Success(PrimeIcons.CIRCLE_FILL),
  [EducationCourseAttendance.PartiallyPresent]: IconColor.Warning(PrimeIcons.CIRCLE_FILL),
  [EducationCourseAttendance.Absent]: IconColor.Error(PrimeIcons.CIRCLE_FILL),
};

export const EducationCoursePerformanceStatusIcons: Record<EducationCoursePerformanceStatus, string> = {
  [EducationCoursePerformanceStatus.Good]: IconColor.Success(PrimeIcons.CIRCLE_FILL),
  [EducationCoursePerformanceStatus.Average]: IconColor.Warning(PrimeIcons.CIRCLE_FILL),
  [EducationCoursePerformanceStatus.Bad]: IconColor.Error(PrimeIcons.CIRCLE_FILL),
};
