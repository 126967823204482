import { NgIf } from "@angular/common";
import { Component, DestroyRef, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  EducationCourseForListFragment,
  EducationExamFragment,
  EducationModuleForSelectionFragment,
} from "@ankaadia/graphql";
import { MultiSelectModule } from "primeng-v17/multiselect";
import { startWith } from "rxjs";
import { FormElementMapModule } from "../../../../shared/from-element-map/form-element-map.module";
import { AppendToBodyDirective } from "../../../../shared/primeng/append-to-body/append-to-body.directive";
import { MultiSelectEditableColumnAutoFocusDirective } from "../../../../shared/primeng/multi-select-editable-column-auto-focus/multi-select-editable-column-auto-focus.directive";
import { MultiSelectReadonlyFixDirective } from "../../../../shared/primeng/multi-select-readonly-fix/multi-select-readonly-fix.directive";
import { MultiSelectSelectedItemsLabelDirective } from "../../../../shared/primeng/multi-select-selectedItemsLabel/multi-select-selectedItemsLabel.directive";
import { MultiSelectSortSelectedValuesOnTopDirective } from "../../../../shared/primeng/multi-select-sort-selected-values-on-top/multi-select-empty.directive";
import { TestIdDirective } from "../../../../shared/test-id/test-id.directive";
import { TranslateDirective } from "../../../../shared/transloco/translate.directive";
import { EducationModuleSelectorComponent } from "../../education-modules/education-module-selector/education-module-selector.component";
import { EducationExamForm } from "../education-exam-dialog/education-exam-form.model";

@Component({
  selector: "app-education-exam-dialog-education-fields",
  templateUrl: "./education-exam-dialog-education-fields.component.html",
  imports: [
    TranslateDirective,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    EducationModuleSelectorComponent,
    MultiSelectModule,
    AppendToBodyDirective,
    MultiSelectEditableColumnAutoFocusDirective,
    MultiSelectReadonlyFixDirective,
    MultiSelectSelectedItemsLabelDirective,
    MultiSelectSortSelectedValuesOnTopDirective,
    FormElementMapModule,
    TestIdDirective,
  ],
})
export class EducationExamDialogEducationFieldsComponent implements OnInit, OnChanges {
  @Input({ required: true })
  form: EducationExamForm;

  @Input({ required: true })
  exam: Partial<EducationExamFragment>;

  @Input({ required: true })
  courses: EducationCourseForListFragment[];

  @Input({ required: true })
  modules: EducationModuleForSelectionFragment[];

  protected selectableModules: EducationModuleForSelectionFragment[];

  protected selectableCourses: EducationCourseForListFragment[];

  constructor(private readonly destroyRef: DestroyRef) {}

  ngOnInit(): void {
    this.form.controls.educationModuleId.valueChanges
      .pipe(startWith(this.form.controls.educationModuleId.value), takeUntilDestroyed(this.destroyRef))
      .subscribe((moduleId) => {
        if (!moduleId) this.form.controls.educationCourseIds.disable();
        else this.form.controls.educationCourseIds.enable();

        this.selectableCourses = this.courses.filter((course) => course.moduleId === moduleId);
      });

    this.form.controls.educationModuleId.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((moduleId) => {
        this.form.controls.educationCourseIds.setValue([]);
        this.form.controls.organizationId.setValue(this.modules.find((m) => m.id === moduleId)?.organizationId);
      });
  }

  ngOnChanges(_: SimpleChanges): void {
    if (this.courses && this.exam && this.modules) {
      this.selectableModules = this.modules.filter(
        (module) => !this.exam._etag || module.organizationId === this.exam.organizationId
      );
    }
  }
}
