import { AsyncPipe, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EducationModuleType, StaticDataType } from "@ankaadia/graphql";
import { DropdownModule } from "primeng-v17/dropdown";
import { FormElementMapModule } from "../../../../shared/from-element-map/form-element-map.module";
import { AppendToBodyDirective } from "../../../../shared/primeng/append-to-body/append-to-body.directive";
import { DropdownEditableColumnAutoFocusDirective } from "../../../../shared/primeng/dropdown-editable-column-auto-focus/dropdown-editable-column-auto-focus.directive";
import { DropdownHideFixDirective } from "../../../../shared/primeng/dropdown-hide-fix/dropdown-hide-fix.directive";
import { DropdownOptionsDirective } from "../../../../shared/primeng/dropdown-options/dropdown-options.directive";
import { DropdownPrePopulateSingleOptionDirective } from "../../../../shared/primeng/dropdown-pre-populate-single-option/dropdown-pre-populate-single-option.directive";
import { DropdownReadonlyFixDirective } from "../../../../shared/primeng/dropdown-readonly-fix/dropdown-readonly-fix.directive";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";
import { TestIdDirective } from "../../../../shared/test-id/test-id.directive";
import { TranslateDirective } from "../../../../shared/transloco/translate.directive";
import { AdHocEducationExamForm } from "../education-exam-ad-hoc-dialog/education-exam-ad-hoc-form.model";

@Component({
  selector: "app-education-exam-dialog-ad-hoc-fields",
  templateUrl: "./education-exam-dialog-ad-hoc-fields.component.html",
  imports: [
    TranslateDirective,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    AppendToBodyDirective,
    DropdownEditableColumnAutoFocusDirective,
    DropdownHideFixDirective,
    DropdownOptionsDirective,
    DropdownPrePopulateSingleOptionDirective,
    DropdownReadonlyFixDirective,
    FormElementMapModule,
    TestIdDirective,
    AsyncPipe,
  ],
})
export class EducationExamDialogAdHocFieldsComponent {
  @Input({ required: true }) form: AdHocEducationExamForm;
  protected readonly EducationModuleType = EducationModuleType;

  readonly types = this.staticDataService.transformEnumToStaticDataModel("EducationModuleType", EducationModuleType);
  readonly languageLevels$ = this.staticDataService.getStaticData(StaticDataType.LanguageModules);

  constructor(private readonly staticDataService: StaticDataService) {}
}
