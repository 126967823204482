import { AsyncPipe, NgIf } from "@angular/common";
import { Component, DestroyRef, Input, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ExamModuleType, ExamType, StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { isEmpty, orderBy, sortBy } from "lodash";
import { SelectItem } from "primeng-v17/api";
import { DropdownModule } from "primeng-v17/dropdown";
import { MultiSelectModule } from "primeng-v17/multiselect";
import { BehaviorSubject, map, Observable, startWith, tap } from "rxjs";
import { FormElementMapModule } from "../../../../shared/from-element-map/form-element-map.module";
import { AppendToBodyDirective } from "../../../../shared/primeng/append-to-body/append-to-body.directive";
import { DropdownEditableColumnAutoFocusDirective } from "../../../../shared/primeng/dropdown-editable-column-auto-focus/dropdown-editable-column-auto-focus.directive";
import { DropdownHideFixDirective } from "../../../../shared/primeng/dropdown-hide-fix/dropdown-hide-fix.directive";
import { DropdownOptionsDirective } from "../../../../shared/primeng/dropdown-options/dropdown-options.directive";
import { DropdownPrePopulateSingleOptionDirective } from "../../../../shared/primeng/dropdown-pre-populate-single-option/dropdown-pre-populate-single-option.directive";
import { DropdownReadonlyFixDirective } from "../../../../shared/primeng/dropdown-readonly-fix/dropdown-readonly-fix.directive";
import { MultiSelectEditableColumnAutoFocusDirective } from "../../../../shared/primeng/multi-select-editable-column-auto-focus/multi-select-editable-column-auto-focus.directive";
import { MultiSelectReadonlyFixDirective } from "../../../../shared/primeng/multi-select-readonly-fix/multi-select-readonly-fix.directive";
import { MultiSelectSelectedItemsLabelDirective } from "../../../../shared/primeng/multi-select-selectedItemsLabel/multi-select-selectedItemsLabel.directive";
import { MultiSelectSortSelectedValuesOnTopDirective } from "../../../../shared/primeng/multi-select-sort-selected-values-on-top/multi-select-empty.directive";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";
import { TestIdDirective } from "../../../../shared/test-id/test-id.directive";
import { TranslateDirective } from "../../../../shared/transloco/translate.directive";
import { AdHocEducationExamForm } from "../education-exam-ad-hoc-dialog/education-exam-ad-hoc-form.model";
import { EducationExamForm } from "../education-exam-dialog/education-exam-form.model";

@Component({
  selector: "app-education-exam-dialog-language-learning-fields",
  templateUrl: "./education-exam-dialog-language-learning-fields.component.html",
  imports: [
    TranslateDirective,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    AppendToBodyDirective,
    DropdownEditableColumnAutoFocusDirective,
    DropdownHideFixDirective,
    DropdownOptionsDirective,
    DropdownPrePopulateSingleOptionDirective,
    DropdownReadonlyFixDirective,
    FormElementMapModule,
    TestIdDirective,
    MultiSelectModule,
    MultiSelectEditableColumnAutoFocusDirective,
    MultiSelectReadonlyFixDirective,
    MultiSelectSelectedItemsLabelDirective,
    MultiSelectSortSelectedValuesOnTopDirective,
    AsyncPipe,
  ],
})
export class EducationExamDialogLanguageLearningFieldsComponent implements OnInit {
  @Input({ required: true }) form: EducationExamForm | AdHocEducationExamForm;
  @Input({ required: true }) existingExamModules: ExamModuleType[];

  private readonly selectedExamModules$ = new BehaviorSubject<ExamModuleType[]>([]);

  private readonly allExamModuleTypeOptions = this.getAllGroupOptions();

  protected readonly examTypes = this.staticDataService.transformEnumToStaticDataModel("ExamType", ExamType);
  protected readonly examInstitutions$ = this.getExamInstitutions();
  protected readonly examModuleTypes$ = this.getExamModules();

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.form.controls.examModules.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(this.form.controls.examModules.value),
        tap((x) => this.selectedExamModules$.next(x))
      )
      .subscribe();
  }

  private getAllGroupOptions(): GroupOptions[] {
    return [
      {
        label: translate("examModules.group-2-part"),
        value: "2-part",
        items: this.getExamModuleOptionsFor(exam2PartModules),
      },
      {
        label: translate("examModules.group-4-part"),
        value: "4-part",
        items: this.getExamModuleOptionsFor(exam4PartModules),
      },
    ];
  }

  private getExamModuleOptionsFor(types: ExamModuleType[]): StaticDataModel[] {
    return orderBy(
      this.getEducationExamModuleTypes().filter((x) => (<string[]>types).includes(x.value)),
      "label"
    );
  }

  private getEducationExamModuleTypes(): StaticDataModel[] {
    const examModuleTypes = this.staticDataService.transformEnumToStaticDataModel("ExamModuleType", ExamModuleType);
    return [...exam4PartModules, ...exam2PartModules].map((examModule) =>
      examModuleTypes.find((staticData) => staticData.value === examModule)
    );
  }

  private getOptionsWithOnlyEnabled(onlyEnabledGroup: "2-part" | "4-part"): GroupOptions[] {
    const disabledItems = this.getGroupOptions((group) => group !== onlyEnabledGroup, true);
    const enabledItems = this.getGroupOptions((group) => group === onlyEnabledGroup, false);
    return [...disabledItems, ...enabledItems];
  }

  private getGroupOptions(predicate: (value: "2-part" | "4-part") => boolean, disabled: boolean): GroupOptions[] {
    return this.allExamModuleTypeOptions
      .filter((x) => predicate(x.value))
      .map((group) => ({
        ...group,
        items: group.items.map((x) => ({ ...x, disabled: disabled })),
      }));
  }

  private getExamModules(): Observable<GroupOptions[]> {
    return this.selectedExamModules$.pipe(
      map((selected) => {
        if (isEmpty(selected)) return this.allExamModuleTypeOptions;
        if (selected.some((typ) => exam2PartModules.includes(typ))) return this.getOptionsWithOnlyEnabled("2-part");
        if (selected.some((typ) => exam4PartModules.includes(typ))) return this.getOptionsWithOnlyEnabled("4-part");
        return this.allExamModuleTypeOptions;
      }),
      map((x) => orderBy(x, "label"))
    );
  }

  private getExamInstitutions(): Observable<StaticDataModel[]> {
    return this.staticDataService
      .getStaticData(StaticDataType.ExamInstitutions)
      .pipe(map((data) => sortBy(data, (entry) => (entry.value === "OTHER" ? 1 : 0))));
  }

  protected readonly isEmpty = isEmpty;
}

interface GroupOptions {
  label: string;
  value: "2-part" | "4-part";
  items: Pick<SelectItem, "value" | "label" | "disabled">[];
}

const exam4PartModules = [
  ExamModuleType.Reading,
  ExamModuleType.Listening,
  ExamModuleType.Speaking,
  ExamModuleType.Writing,
];

const exam2PartModules = [ExamModuleType.Oral, ExamModuleType.Written];
