<app-table
  *appTranslate="let t"
  [items]="exams"
  [title]="title"
  [columns]="columns"
  [newOperations]="newOperations"
  [tableOperations]="tableOperations"
  [(showSidebar)]="showSidebar"
  [showSearch]="search"
  sortField="examDateAndTime"
  [sortOrder]="-1"
  [virtualScroll]="virtualScroll"
>
  <ng-template class="flex flex-0 mr-2 mw-1" *ngIf="captionTemplate" #caption>
    <ng-container *ngTemplateOutlet="captionTemplate"></ng-container>
  </ng-template>

  <ng-container *ngIf="!isReadOnly && selectedExam.kind === 'education' && (courses$ | async) as courses">
    <app-education-exam-dialog
      *ngIf="showSidebar"
      [exam]="selectedExam.data"
      [courses]="courses"
      [modules]="modules"
      (saved)="save($event)"
      (closed)="showSidebar = false"
    ></app-education-exam-dialog>
  </ng-container>

  <ng-container *ngIf="!isReadOnly && selectedExam.kind === 'ad-hoc'">
    <app-education-exam-ad-hoc-dialog
      *ngIf="showSidebar"
      [exam]="selectedExam.data"
      (saved)="saveAdHoc($event)"
      (closed)="showSidebar = false"
    ></app-education-exam-ad-hoc-dialog>
  </ng-container>
</app-table>
