import { Injectable } from "@angular/core";
import { FormBuilder, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import {
  EducationExamModuleResultInformation,
  EducationExamResultFullDataFragment,
  ExamModuleType,
} from "@ankaadia/graphql";
import { isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { ExamFragment } from "../education-exams/education-exam-table/education-exam-table.component";
import { EducationExamModuleInformationForm, EducationExamResultsForm } from "./education-exam-results-form.model";

@Injectable({ providedIn: "root" })
export class EducationExamResultFormService {
  constructor(private readonly fb: FormBuilder) {}

  createForm(exam: ExamFragment, data: Partial<EducationExamResultFullDataFragment>): EducationExamResultsForm {
    return this.fb.group<EducationExamResultsForm["controls"]>(
      {
        examResult: this.fb.control(data.examResult, Validators.required),
        passedExamModules: this.fb.control(data.passedExamModules),
        candidateId: this.fb.control(data.candidateId),
        candidateOrganizationId: this.fb.control(data.candidateOrganizationId),
        _etag: this.fb.control(data._etag),
        examId: this.fb.control(data.examId),
        id: this.fb.control(data.id ?? uuidv4()),
        organizationId: this.fb.control(data.organizationId),
        examModuleInformation: this.fb.array<EducationExamModuleInformationForm>(
          this.createExamModuleInformation(
            exam?.examModules,
            exam?.examModules ?? [],
            data ? data.examModuleInformation : []
          )
        ),
      },
      { validators: this.passedExamModulesValidator(exam).bind(this) }
    );
  }

  private passedExamModulesValidator(exam: ExamFragment): ValidatorFn {
    return (form: EducationExamResultsForm) => this.validatePassedExamResultAndPassedModules(form, exam?.examModules);
  }

  private validatePassedExamResultAndPassedModules(
    form: EducationExamResultsForm,
    examModules: ExamModuleType[]
  ): ValidationErrors | null {
    if (isEmpty(examModules)) return null;

    if (isExamResult(["PASSED"]) && notAllModulesPassed()) {
      form.controls.passedExamModules.setErrors({ passedExamButNotAllModulesPassed: true });
      return { passedExamButNotAllModulesPassed: true };
    }

    if (isExamResult(["PASSED", "PARTIALLYPASSED"]) && noModulesPassed()) {
      form.controls.passedExamModules.setErrors({ required: true });
      return { required: true };
    }

    if (isExamResult(["PARTIALLYPASSED"]) && allModulesPassed()) {
      form.controls.passedExamModules.setErrors({ partiallyPassedExamButAllModulesPassed: true });
      return { partiallyPassedExamButAllModulesPassed: true };
    }

    if (isExamResult(["NOT_TAKEN", "FAILED"]) && !isEmpty(form.controls.passedExamModules.value)) {
      form.controls.passedExamModules.setErrors({ notPassedExamButModulesPassed: true });
      return { notPassedExamButModulesPassed: true };
    }

    form.controls.passedExamModules.setErrors(null);
    return null;

    function allModulesPassed(): boolean {
      return !isEmpty(examModules) && form.controls.passedExamModules.value?.length >= examModules?.length;
    }

    function noModulesPassed(): boolean {
      return isEmpty(form.controls.passedExamModules.value) && !isEmpty(examModules);
    }

    function notAllModulesPassed(): boolean {
      return !isEmpty(examModules) && form.controls.passedExamModules.value?.length != examModules?.length;
    }

    function isExamResult(examResultTypes: ExamResultType[]): boolean {
      return examResultTypes.includes(form.controls.examResult.value as ExamResultType);
    }
  }

  private createExamModuleInformation(
    examModules: ExamModuleType[],
    enabledExamModules: ExamModuleType[],
    data: EducationExamModuleResultInformation[]
  ): EducationExamModuleInformationForm[] {
    return (
      examModules?.map((examModule) =>
        this.createSingleExamModuleInformation(
          examModule,
          data?.find((x) => x.examModule == examModule),
          !enabledExamModules.includes(examModule)
        )
      ) ?? []
    );
  }

  private createSingleExamModuleInformation(
    examModule: ExamModuleType,
    data: EducationExamModuleResultInformation,
    disabled: boolean
  ): EducationExamModuleInformationForm {
    return this.fb.group<EducationExamModuleInformationForm["controls"]>({
      resultDetails: this.fb.control({ value: data?.resultDetails, disabled: disabled }),
      id: this.fb.control(data?.id ?? uuidv4()),
      examModule: this.fb.control(examModule),
    });
  }
}

type ExamResultType = "PASSED" | "NOT_TAKEN" | "FAILED" | "PARTIALLYPASSED";
