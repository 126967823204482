import { AsyncPipe, NgIf } from "@angular/common";
import { Component, DestroyRef, Input, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { getAllEducationExamPaymentRoles } from "@ankaadia/ankaadia-shared";
import { StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { CalendarModule } from "primeng-v17/calendar";
import { CheckboxModule } from "primeng-v17/checkbox";
import { DropdownModule } from "primeng-v17/dropdown";
import { InputTextModule } from "primeng-v17/inputtext";
import { filter, map, Observable, tap } from "rxjs";
import { DateTimeModule } from "../../../../shared/date-time/date-time.module";
import { FormElementMapModule } from "../../../../shared/from-element-map/form-element-map.module";
import { AppendToBodyDirective } from "../../../../shared/primeng/append-to-body/append-to-body.directive";
import { CalendarOptionsDirective } from "../../../../shared/primeng/calendar-options/calendar-options.directive";
import { CalendarUtcFixDirective } from "../../../../shared/primeng/calendar-utc-fix/calendar-utc-fix.directive";
import { DropdownEditableColumnAutoFocusDirective } from "../../../../shared/primeng/dropdown-editable-column-auto-focus/dropdown-editable-column-auto-focus.directive";
import { DropdownHideFixDirective } from "../../../../shared/primeng/dropdown-hide-fix/dropdown-hide-fix.directive";
import { DropdownOptionsDirective } from "../../../../shared/primeng/dropdown-options/dropdown-options.directive";
import { DropdownPrePopulateSingleOptionDirective } from "../../../../shared/primeng/dropdown-pre-populate-single-option/dropdown-pre-populate-single-option.directive";
import { DropdownReadonlyFixDirective } from "../../../../shared/primeng/dropdown-readonly-fix/dropdown-readonly-fix.directive";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";
import { TestIdDirective } from "../../../../shared/test-id/test-id.directive";
import { TranslateDirective } from "../../../../shared/transloco/translate.directive";
import { AdHocEducationExamForm } from "../education-exam-ad-hoc-dialog/education-exam-ad-hoc-form.model";
import { EducationExamForm } from "../education-exam-dialog/education-exam-form.model";

@Component({
  selector: "app-education-exam-dialog-general-fields",
  templateUrl: "./education-exam-dialog-general-fields.component.html",
  imports: [
    TranslateDirective,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    FormElementMapModule,
    TestIdDirective,
    DateTimeModule,
    CheckboxModule,
    CalendarModule,
    AppendToBodyDirective,
    CalendarOptionsDirective,
    CalendarUtcFixDirective,
    DropdownModule,
    DropdownEditableColumnAutoFocusDirective,
    DropdownHideFixDirective,
    DropdownOptionsDirective,
    DropdownPrePopulateSingleOptionDirective,
    DropdownReadonlyFixDirective,
    AsyncPipe,
  ],
})
export class EducationExamDialogGeneralFieldsComponent implements OnInit {
  @Input({ required: true }) form: EducationExamForm | AdHocEducationExamForm;

  private readonly allowedRoles: string[] = getAllEducationExamPaymentRoles();

  protected paymentRoles$: Observable<StaticDataModel[]> = this.staticDataService
    .getStaticData(StaticDataType.ProcessRole)
    .pipe(map((x) => x.filter((y) => this.allowedRoles.includes(y.value))));

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.form.controls.plannedExamDateAndTimeConfirmed.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((x) => x && !!this.form.controls.plannedExamDateAndTime.value),
        tap((_) =>
          this.form.controls.examDateAndTime.setValue({
            date: this.form.controls.plannedExamDateAndTime.value.date,
            hasTime: this.form.controls.plannedExamDateAndTime.value.hasTime,
          })
        )
      )
      .subscribe();
  }
}
